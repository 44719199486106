/* Reset css */
/* *{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
 */
/* Home css */

/* picture{
  display: flex;
} */
.flow-text{
  font-size: 1.6rem;
}

.sm-red-text{
  color: #D80029;
}
.intro{
  height: 100vh;
  position: relative;
  background-color: #E5451F;
}
.intro-overlay{
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: black;
}
.intro video{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.intro h1 {
    color: #fff;
    /* text-transform: uppercase; */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 3rem;
    font-weight: 900;
    text-align: center;
}
.intro::after{
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1.2rem;
  background-color: #D80029;
}
.sm-mouse-scroll{
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: #D80029;
  padding: 1.5rem;
  border-radius: 50% 50% 0 0;
}
/* .sm-mouse-scroll svg{
  height: 2.5rem;
  width: 2.5rem;
} */
.cards-section{
  background-color: #D80029;
  text-align: center;
  position: relative;
}
/* .cards-section::after{
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1.2rem;
  background-color: #D80029;
  z-index: 2;
} */
.card-overlay{
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(194, 0, 36, 0.6);
  background-color: rgba(0, 0, 0, 0.4);
  background-blend-mode: multiply;
  z-index: 1;
  transition: all 0.3s ease-in;
}
.card-overlay:hover{
  background-color: rgba(55, 0, 10, 0.6);
}
.card-bg-image{
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.sm-aism-logo-wrapper{
  width: 10rem;
}
.sm-slider-diagnosis{
  padding: 0.75rem;
}
.swiper {
  width: 100%;
  height: 100%;
}
.sm-swiper-slide{
  height: inherit;
  min-height: 300px;
  padding: 0 3rem;
  font-size: 2rem;
}
.sm-swiper-ph{
  padding: 0 2rem;
}
.sm-info{
  background-color: #D80029;
  color: #fff;
}
.sm-info-columns{
  display: flex;
  flex-direction: row;
}
.sm-info-columns :nth-child(1){
  order: 1;
}
.sm-info-columns :nth-child(2){
  order: 2;
}
.sm-info-columns :nth-child(3){
  order: 3;
}
.sm-info-columns :nth-child(4){
  order: 4;
}
.sm-info-body
{
  padding: 4rem 8rem;
  font-size: 1.4rem;
}
.sm-info-swiper{
  width: 100%;
  overflow: hidden;
}
.swiper-button-next.info, .swiper-button-prev.info{
  color: #fff;
}
.sm-info-swiper .swiper-pagination-bullet-active{
  background: #fff;
}
.sm-adv-info-columns .column:nth-child(1){
  background-color: #fff;
  color: #353535;
}
.sm-adv-info-columns .column:nth-child(2){
  background-color: #353535;
  color: #fff;
  font-size: 1.4rem;
  padding: 8rem;
}
.sm-logo-adv-v{
  height: 18rem;
}
.sm-facebook-share-column{
  padding: 8rem;
}
.sm-facebook-share-img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.sm-facebook-share-pic{
  display: flex;
  height: 100%;
}
.sm-facebook-icon-btn{
  width: 2rem;
  height: 2rem;
}
.sm-banner-section::before{
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  background: url("../assets/img/sm/sclerosi-multipla-cover.avif"), url("../assets/img/sm/sclerosi-multipla-cover.webp"), url("../assets/img/sm/sclerosi-multipla-cover.jpg");
  background-position: center;
  background-repeat: no-repeat;
}
.sm-banner-section{
  /* background: linear-gradient(
    45deg, rgb(245 137 42) 0%, rgba(229,69,31,1) 100%); */
    position: relative;
    background-color: rgba(0, 0, 0, .4);
}



@media screen and (max-width: 754px) {
  .intro h1{
    font-size: 1.8rem;
    width: 100%;
  }
  .card-wrapper{
    height: 300px;
  }
  .sm-slider-diagnosis{
    padding: 0.75rem 0;
  }
  .sm-swiper-slide{
    padding: 0 2rem;
    font-size: 1.2rem;
  }
  .sm-info-columns{
    display: flex;
    flex-direction: column;
  }
  .sm-info-columns :nth-child(1){
    order: 1;
  }
  .sm-info-columns :nth-child(2){
    order: 2;
  }
  .sm-info-columns :nth-child(3){
    order: 4;
  }
  .sm-info-columns :nth-child(4){
    order: 3;
  }
  .flow-text{
    font-size: 1.1rem;
  }
  .sm-info-body
  {
    padding: 2rem;
    font-size: 1.1rem;
  }
  .sm-adv-info-columns .column:nth-child(1), .sm-adv-info-columns .column:nth-child(2){
    padding: 2rem;
    font-size: 1.1rem;
  }
  .sm-facebook-share-column{
    padding: 2rem;
    background-color: rgb(0 0 0 / 50%);
    min-height: 300px;
    position: relative;
    color: #fff;
  }
  .sm-facebook-share-column::before{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("../assets/img/home/facebook-share.avif"), url("../assets/img/home/facebook-share.webp"), url("../assets/img/home/facebook-share.jpg"), no-repeat;
    background-position: center;
    background-size: 160%;
    z-index: -1;
  }
}
@media screen and (min-width: 769px) and (max-width: 1023px){
  .intro h1{
    font-size: 2rem;
    width: 100%;
  }
  .flow-text{
    font-size: 1.1rem;
  }
  .sm-info-body{
    padding: 2rem;
    font-size: 1.1rem;
  }
  .sm-adv-info-columns .column:nth-child(1), .sm-adv-info-columns .column:nth-child(2){
    padding: 2rem;
    font-size: 1.1rem;
  }
  .sm-facebook-share-column{
    padding: 2rem;
  }
}
@media screen and (min-width: 1024px) and (max-width: 1215px){
  .intro h1{
    font-size: 2.5rem;
    width: 100%;
  }
  .flow-text{
    font-size: 1.2rem;
  }
  .sm-info-body{
    padding: 2rem;
    font-size: 1.2rem;
  }
  .sm-adv-info-columns .column:nth-child(1), .sm-adv-info-columns .column:nth-child(2){
    padding: 2rem;
    font-size: 1.2rem;
  }
  .sm-facebook-share-column{
    padding: 2rem;
  }
}
@media screen and (min-width: 1216px) and (max-width: 1407px){
  .intro h1{
    font-size: 2.6rem;
    width: 100%;
  }
  .flow-text{
    font-size: 1.3rem;
  }
  .sm-info-body{
    padding: 2rem 3rem;
    font-size: 1.3rem;
  }
  .sm-adv-info-columns .column:nth-child(1), .sm-adv-info-columns .column:nth-child(2){
    padding: 2rem 3rem;
    font-size: 1.3rem;
  }
  .sm-facebook-share-column{
    padding: 2rem 3rem;
  }
}
@media screen and (min-width: 1408px){
  .sm-swiper-slide{
    min-height: 450px;
  }
}
